import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import Media from "react-media"
import LayoutDirectory from "components/layoutDirectory"
import IconFacebook from "components/svg/IconFacebook"
import IconInstagram from "components/svg/IconInstagram"
import IconTwitter from "components/svg/IconTwitter"
import IconYouTube from "components/svg/IconYouTube"
import { Button } from "components/button"
import { noise } from "components/css/noise"
import AwardAnnouncement from "../components/svg/AwardAnnouncement"

const StyledPageWrap = styled.div`
  position: relative;
  z-index: 1;
  max-width: 40em;
  margin-right: auto;
  margin-left: auto;
`

const StyledPageContent = styled.div`
  position: relative;
  z-index: 1;
  padding: 2em 0;

  a:not(${Button}) {
    text-decoration: underline;
    .can-hover &:hover,
    &:active {
      color: var(--color-gray-text)
    }
  }
  & > div {
    margin-bottom: 3em;
  }
  @media ( min-width: 60em ) and ( max-width: 64em ) {
    padding-top: 3em;
  }
  @media ( min-width: 64em ) {
    grid-column: 1;
    grid-row: 1;
  }
`


const FaqPage = ({ data }) => {

    return (
      <>
        <Helmet>
            <title>{data.faq.title} | {data.site.siteMetadata.title}</title>
        </Helmet>
        <LayoutDirectory>
          <StyledPageWrap>
            <StyledPageContent>
              <h1 className="pageTitle">{data.faq.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: data.faq.content }} />
            </StyledPageContent>
          </StyledPageWrap>
        </LayoutDirectory>
        <AwardAnnouncement />
      </>
    )
}

export const query = graphql`
    query {
        faq: directusFaq {
            title
            content
        }
        site {
            id
            siteMetadata {
                title
            }
        }
    }
`

export default FaqPage